<template>
  <div class="relative">
    <comments-header v-if="!disableHeader" />
    <div class="px-9 pt-4 pb-6 min-h-120">
      <div
        v-if="isCommentsListLoading"
        class="flex items-center justify-center min-h-120"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <template v-else-if="commentsList.length > 0">
        <comment-card
          v-for="(comment, commentIndex) in commentsList"
          :key="'comment' + commentIndex"
          :default-card-color="setDefaultColor(comment.submissionStageKeyName)"
          :comment="comment"
          :modal-title="modalTitle"
        />
        <div id="commentScrollToLast" />
      </template>
      <div
        v-else
        class="flex items-center justify-center min-h-120"
      >
        <span class="text-black">
          No Comments found.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useComments from './comments.js';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';

export default {
    name: 'BrandAssuranceComments',

    components: {
        CommentCard: defineAsyncComponent(() => import('./CommentCard.vue')),
        CommentsHeader: defineAsyncComponent(() => import('./CommentsHeader.vue')),
        BaseSvg
    },

    props: {
        disableHeader: {
            type: Boolean,
            default: false,
            description: 'Hide Header flag'
        },
        modalTitle: {
            type: String,
            default: '',
            description: 'Modal Title to differentiate between licensee and internal comments'
        }
    },

    setup (props) {
        const { commentsList, isCommentsListLoading } = useComments();

        const setDefaultColor = (key) => {
            switch (key) {
            case 'Concept':
                return 'custom-color-green-blue';
            case 'Contractual':
                return 'custom-color-gray';
            case 'Painted Sculpt':
                return 'custom-color-orange';
            case 'Pre-Production':
                return 'custom-color-blue';
            case 'Revised ':
                return 'custom-color-blue';
            case 'Strike-Off':
                return 'custom-color-olive';
            case 'Other':
                return 'custom-color-dark-blue';
            case 'Final':
                return 'custom-color-green';
            case 'Sculpt':
                return 'custom-color-violet';
            case 'Packaging Mechanical':
                return 'custom-color-red';
            case 'Color Proof/Chromalin':
                return 'custom-color-pink';
            case 'Annual Samples':
                return 'custom-color-yellow';
            case 'Production':
                return 'custom-color-green';
            case 'Prototype':
                return 'custom-color-teal';
            default:
                return 'custom-color-pink';
            }
        };

        return {
            commentsList,
            setDefaultColor,
            isCommentsListLoading
        };
    }
};
</script>
